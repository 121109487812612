import React, { useState } from "react";
import { motion, useViewportScroll, useTransform } from "framer-motion";
import envelopeBg from './images/envelope.jpg';
import text from './images/text.png';
import './App.scss';

function App() {
  return <LetterScene />;
}


const letterStyle = {
  width: "calc(100% - 1rem)",
  height: "calc(100% - 1rem)",
  top: "0.5rem",
  left: "0.5rem",
  backgroundColor: "#ffe2e7",
  backgroundImage: `url(${text})`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "auto 100%",
  overflow: "hidden",
  padding: "1rem",
  boxSizing: "border-box",
  position: "absolute",
};

function Letter() {
  const { scrollYProgress } = useViewportScroll();
  const scaleAnim = useTransform(scrollYProgress, [0, 0.5, 1], [1, 1, 1.5]);
  const yPosAnim = useTransform(scrollYProgress, [0, 0.4, 1], [0, -250, -100]);

  return (
    <motion.div
      style={{
        ...letterStyle,
        scale: scaleAnim,
        y: yPosAnim,
      }}
    >
    </motion.div>
  );
}

const envelopeStyle = {
  width: "82vw",
  height: "41vw",
  scale: 1,
  position: "fixed",
  top: "8%",
  left: "9vw",
  boxShadow: `rgba(0, 0, 0, 0.5) 0px 0px 15px 5px`,
  zIndex:50
};

const frontfaceStyle = {
  width: "100%",
  height: "100%",
  backgroundColor: "#000000",
  backgroundImage: `url(${envelopeBg})`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "100% 100%",
  position: "absolute",
  left: 0,
  top: 0,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

function Envelope({ children }) {
  const [ffLayer, setFfLayer] = useState(0);
  const { scrollYProgress } = useViewportScroll();
  const scaleAnim = useTransform(scrollYProgress, [0, 0.5, 1], [1, 1.2, 0.8]);
  const yPosAnim = useTransform(scrollYProgress, [0, 0.5, 1], [0, 100, 200]);
  const zRotAnim = useTransform(scrollYProgress, [0, 0.5, 1], [0, 3, 0]);

  scrollYProgress.onChange((x) => {
    setFfLayer(x > 0.4 ? -1 : 0);
  });

  return (
    <motion.div
      style={{
        ...envelopeStyle,
        scale: scaleAnim,
        y: yPosAnim,
        rotateZ: zRotAnim,
      }}
    >
      {children}
      <div style={{ ...frontfaceStyle, zIndex: ffLayer }}>
        
      </div>
    </motion.div>
  );
}

const letterSceneStyle = {
  height: "200vh",
};

function LetterScene() {
  return (
    <div style={letterSceneStyle}>
      <Envelope>
        <Letter />
      </Envelope>
      <div id="stars-group-1"></div>
      <div id="stars-group-2"></div>
      <div id="stars-group-3"></div>
      <div id="stars-group-4"></div>
      <div id="stars-group-5"></div>
      <div id="stars-group-6"></div>
    </div>
  );
}

export default App;
